import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import logo from "../../images/logo.png";
import "./style.scss";

// Social Media Links Component
const SocialMediaLinks = () => {
  const socialLinks = [
    {
      href: "https://www.facebook.com/Chinmaya-Vidyalaya-Vasant-Vihar-Delhi-136530329794825/",
      icon: "fab fa-facebook-f",
    },
    {
      href: "https://instagram.com/chinmaya_vidyalaya_nd?igshid=NTdlMDg3MTY=",
      icon: "fab fa-instagram",
    },
    {
      href: "https://www.youtube.com/channel/UCQqKBe4Q5ZgC7Y-KoCPzGyQ",
      icon: "fab fa-youtube",
    },
    {
      href: "https://www.linkedin.com/company/chinmaya-vidyalaya-vasant-vihar/?viewAsMember=true",
      icon: "fab fa-linkedin-in",
    },
  ];

  return (
    <div className="socialmedia">
      {socialLinks.map((link, index) => (
        <a
          key={index}
          target="_blank"
          href={link.href}
          className="social__icon"
          rel="noopener noreferrer"
        >
          <i className={link.icon}></i>
        </a>
      ))}
    </div>
  );
};

// Dropdown Menu Component
const DropdownMenu = ({ links, headings, className }) => {
  return (
    <div className={`navbar__links__link__dropdown ${className}`}>
      <div className="navbar__links__link__dropdown__blur"></div>
      <div className="navbar__links__link__dropdown__content">
        <div className="row">
          {headings ? (
            headings.map((heading, index) => (
              <div key={index} className={`col col-3`}>
                {heading.title && (
                  <div className="navbar__links__link__dropdown__heading">
                    {heading.isLink ? (
                      <Link to={heading.link || "/"}>{heading.title}</Link>
                    ) : (
                      heading.title
                    )}
                  </div>
                )}
                {links
                  .filter((link) => link.category === heading.category)
                  .map((link, linkIndex) =>
                    link.isExternal ? (
                      <a
                        key={linkIndex}
                        href={link.href}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="navbar__links__link__dropdown__link"
                      >
                        {link.text}
                      </a>
                    ) : (
                      <Link
                        key={linkIndex}
                        to={link.to}
                        className="navbar__links__link__dropdown__link"
                      >
                        {link.text}
                      </Link>
                    )
                  )}
              </div>
            ))
          ) : (
            <div className="col col-12">
              {links.map((link, index) =>
                link.isExternal ? (
                  <a
                    key={index}
                    href={link.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="navbar__links__link__dropdown__link"
                  >
                    {link.text}
                  </a>
                ) : (
                  <Link
                    key={index}
                    to={link.to}
                    className="navbar__links__link__dropdown__link"
                  >
                    {link.text}
                  </Link>
                )
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

// Main Navbar Component
export default function Navbar() {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const nav = document.querySelector("nav");
      const layout = document.getElementById("layout");

      if (!nav || !layout) return;

      const elDistanceToTop = nav.getBoundingClientRect().top;

      if (window.scrollY === 0) {
        nav.classList.remove("navbar--sticky");
        layout.classList.remove("layout--stickypadding");
        setIsSticky(false);
      } else if (elDistanceToTop < 0) {
        nav.classList.add("navbar--sticky");
        layout.classList.add("layout--stickypadding");
        setIsSticky(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const connectLinks = [
    { to: "/contact-information/", text: "Contact Us" },
    { to: "/alumni/", text: "Alumni" },
    { to: "/careers/", text: "Careers" },
  ];

  const aboutLinks = [
    {
      to: "/chinmaya-mission-trust/",
      text: "Chinmaya Mission",
      category: "about",
    },
    {
      to: "/chinmaya-vision-programme",
      text: "Chinmaya Vision Program",
      category: "about",
    },
    { to: "/gurudevs-journey/", text: "Gurudev's Journey", category: "about" },
    {
      to: "/principal-message/",
      text: "Principal's Message",
      category: "about",
    },
    {
      to: "/managing-committee/",
      text: "Managing Committee",
      category: "about",
    },
    {
      to: "/pta-executive-committee/",
      text: "PTA Executive Committee",
      category: "about",
    },
    {to:"/school-committees/", text:"School Committees", category:"about"},
    { to: "/hall-of-fame/", text: "Awards", category: "about" },
    { to: "/press/", text: "Press", category: "about" },
  ];

  const informationLinks = [
    {
      isExternal: true,
      href: "http://student.iwsportal.com/",
      text: "Online Fee Payment",
      category: "school",
    },
    { to: "/fee-structure/", text: "Fee Structure", category: "school" },
    { to: "/cbse-corner/", text: "CBSE Corner", category: "school" },
    {
      to: "/transfer-certificate/",
      text: "Transfer Certificate",
      category: "school",
    },
    {
      to: "/rules-and-regulations/",
      text: "Rules & Regulations",
      category: "school",
    },
    { to: "/transportation/", text: "Transportation", category: "school" },
    { to: "/school-events/", text: "School Events", category: "school" },
    { to: "/circulars/", text: "School Circulars", category: "school" },
    { to: "/school-uniform/", text: "School Uniform", category: "school" },
    { to: "/infrastructure/", text: "Infrastructure", category: "school" },
    { to: "/school-gallery/", text: "School Gallery", category: "school" },
    {
      isExternal: true,
      href: "http://abhyudya.chinmayavvdelhi.ac.in/",
      text: "Annual E-Magazine",
      category: "school",
    },
    { to: "/podcasts/", text: "Podcasts", category: "school" },
  ];

  const informationHeadings = [
    {
      title: "School Information",
      category: "school",
      isLink: false,
    },
    {
      title: "Academics",
      category: "academics",
      isLink: true,
      link: "/academics/",
    },
    {
      title: "Co-Curriculars",
      category: "co-curricular",
      isLink: false,
    },
    {
      title: "Achievements",
      category: "achievements",
      isLink: false,
    },
    {
      title: "Infrastructure",
      category: "infrastructure",
      isLink: true,
      link: "/infrastructure",
    },
  ];

  const academicLinks = [
    { to: "/subjects/", text: "Subjects", category: "academics" },
    { to: "/syllabus/", text: "Syllabus", category: "academics" },
    {
      to: "/exam-schedule/",
      text: "Examination Schedule",
      category: "academics",
    },
  ];

  const coCurricularLinks = [
    {
      to: "/performing-arts/",
      text: "Performing Arts",
      category: "co-curricular",
    },
    { to: "/club/", text: "Clubs", category: "co-curricular" },
    { to: "/visual-arts/", text: "Visual Arts", category: "co-curricular" },
    { to: "/sports/", text: "Sports", category: "co-curricular" },
    { to: "/vyaktitva/", text: "Vyaktitva", category: "co-curricular" },
  ];

  const achievementLinks = [
    { to: "/cbse-results/", text: "CBSE Results", category: "achievements" },
    {
      to: "/achievements/external-events",
      text: "External Events",
      category: "achievements",
    },
    { to: "/school-council", text: "School Council", category: "achievements" },
    {
      to: "https://mimamsa.chinmayavvdelhi.ac.in/winners",
      text: "Mimamsa 2024 Winners",
      category: "achievements",
    },
  ];

  const infrastructureLinks = [
    { to: "/atl/", text: "ATL Lab", category: "infrastructure" },
    { to: "/laboratories/", text: "Laboratories", category: "infrastructure" },
    { to: "/library/", text: "Library", category: "infrastructure" },
  ];

  const admissionsLinks = [
    { to: "/nursery-admissions/", text: "Nursery" },
    { to: "/admissions/", text: "Other Classes" },
    { to: "/online-registration-process/", text: "Application Process" },
    { to: "/registrations/", text: "Registrations" },
  ];

  return (
    <nav className="navbar">
      <Link style={{ textDecoration: "none" }} to="/">
        <div className="navbar__logo">
          <img className="navbar__logo__img" alt="Chinmaya Logo" src={logo} />
          <div className="navbar__logo__text">
            Chinmaya Vidyalaya
            <br />
            Vasant Vihar, New Delhi
          </div>
        </div>
      </Link>

      <div className="navbar__links">
        <div className="menu1">
          <div className="navbar__links__link">
            <Link to="/contact-information/">
              Connect <i className="fas fa-chevron-down"></i>
            </Link>
            <DropdownMenu links={connectLinks} />
          </div>

          <div className="navbar__links__link">
            <a
              className=""
              href="https://cvvv.iwsportal.com:572/Account/Login.aspx"
              target="_blank"
              rel="noopener noreferrer"
            >
              Login
            </a>
          </div>
          <div className="navbar__links__link">
            <a className="" href="/blog/">
              Blog
            </a>
          </div>
          <a
            className="e-newsletter-btn"
            href="http://abhyudya.chinmayavvdelhi.ac.in/?page=0"
            style={{ backgroundColor: "#D75431", color: "white" }}
          >
            E-MAGAZINE
          </a>
          <a
            className="e-newsletter-btn"
            href="/e-newsletters/"
            target="_blank"
            rel="noopener noreferrer"
          >
            E-NEWSLETTERS
          </a>
        </div>
        <div className="menu2">
          <div className="navbar__links__link">
            <Link to="/">Home</Link>
          </div>

          <div className="navbar__links__link">
            About Us <i className="fas fa-chevron-down"></i>
            <DropdownMenu
              className={"navbar__links__link__dropdown--1"}
              links={aboutLinks}
            />
          </div>
          <div className="navbar__links__link">
            Information <i className="fas fa-chevron-down"></i>
            <DropdownMenu
              className={"navbar__links__link__dropdown--2"}
              links={[
                ...informationLinks,
                ...academicLinks,
                ...coCurricularLinks,
                ...achievementLinks,
                ...infrastructureLinks,
              ]}
              headings={informationHeadings}
            />
          </div>
          <div className="navbar__links__link">
            Admissions <i className="fas fa-chevron-down"></i>
            <DropdownMenu
              className={"navbar__links__link__dropdown--3"}
              links={admissionsLinks}
            />
          </div>
          <SocialMediaLinks />
        </div>
      </div>
    </nav>
  );
}
