import React from "react";
import "./style.scss";

export default function StickySocial() {
  return (
    <div className="stickysocial">
      <a
        target="_blank"
        href="https://www.facebook.com/Chinmaya-Vidyalaya-Vasant-Vihar-Delhi-136530329794825/"
        className="stickysocial__icon"
      >
        <i className="fa-brands fa-facebook-f"></i>
      </a>
      <a
        target="_blank"
        href="https://instagram.com/chinmaya_vidyalaya_nd?igshid=NTdlMDg3MTY="
        className="stickysocial__icon"
      >
        <i className="fa-brands fa-instagram"></i>
      </a>
      <a
        target="_blank"
        href="https://www.youtube.com/channel/UCQqKBe4Q5ZgC7Y-KoCPzGyQ"
        className="stickysocial__icon"
      >
        <i className="fa-brands fa-youtube"></i>
      </a>
      <a
        target="_blank"
        href="https://www.linkedin.com/company/chinmaya-vidyalaya-vasant-vihar/?viewAsMember=true"
        className="stickysocial__icon"
      >
        <i className="fa-brands fa-linkedin-in"></i>
      </a>
      {/* <a
        target="_blank"
        href="https://twitter.com/CV_NewDelhi"
        className="stickysocial__icon"
      >
        <i className="fa-brands fa-x-twitter"></i>
      </a> */}
    </div>
  );
}
