import React, { useEffect } from "react";
import "./style.scss";
import GoogleReviewWidget from "../GoogleReviewWidget";

// import logoWhite from "../../images/logo.png";
// import classEdge from "../../images/Tata-Class-Edge.png";

export default function Sitemap() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://platform.twitter.com/widgets.js";
    document
      .getElementsByClassName("sitemap__twittercontainer")[0]
      .appendChild(script);
  }, []);

  return (
    <div className="sitemap">
      <div className="sitemap__linkscontainer">
        <div className="row">
          <div className="col col-12 col-lg-8 sitemap__linkscontainer__leftsection">
            <div className="row sitemap__linkscontainer__row">
              <div className="col col-6 col-md-4 col-lg-3">
                <div className="sitemap__linkscontainer__card">
                  <div className="sitemap__linkscontainer__card__heading">
                    Quick Links
                  </div>

                  <div className="sitemap__linkscontainer__card__link">
                    <a href="/contact-information/">Contact Information</a>
                  </div>
                  <div className="sitemap__linkscontainer__card__link">
                    <a href="/careers/">Careers</a>
                  </div>
                  <div className="sitemap__linkscontainer__card__link">
                    <a href="/alumni/">Alumni</a>
                  </div>
                  <div className="sitemap__linkscontainer__card__link">
                    <a href="http://student.iwsportal.com/">
                      Online Fee Payment
                    </a>
                  </div>
                  <div className="sitemap__linkscontainer__card__link">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://cvvv.iwsportal.com:572/Account/Login.aspx"
                    >
                      Parent Portal
                    </a>
                  </div>
                  <div className="sitemap__linkscontainer__card__link">
                    <a href="https://cbse.nic.in/">CBSE Website</a>
                  </div>
                  <div className="sitemap__linkscontainer__card__link">
                    <a href="/cbse-results/">Achievers</a>
                  </div>
                </div>
              </div>
              <div className="col col-6 col-md-4 col-lg-3">
                <div className="sitemap__linkscontainer__card">
                  <div className="sitemap__linkscontainer__card__heading">
                    About
                  </div>

                  <div className="sitemap__linkscontainer__card__link">
                    <a href="/chinmaya-mission-trust/"> Chinmaya Mission</a>
                  </div>
                  <div className="sitemap__linkscontainer__card__link">
                    <a href="/chinmaya-vision-programme/">
                      Chinmaya Vision Program
                    </a>
                  </div>
                  <div className="sitemap__linkscontainer__card__link">
                    <a href="/gurudevs-journey">Gurudev's Journey</a>
                  </div>
                  <div className="sitemap__linkscontainer__card__link">
                    <a href="/principal-message/">Principal's Message</a>
                  </div>
                  <div className="sitemap__linkscontainer__card__link">
                    <a href="/managing-committee/">Managing Committee</a>
                  </div>
                  <div className="sitemap__linkscontainer__card__link">
                    <a href="/hall-of-fame/">School Awards</a>
                  </div>
                  <div className="sitemap__linkscontainer__card__link">
                    <a href="/press/">Press</a>
                  </div>
                </div>
              </div>
              <div className="col col-6 col-md-4 col-lg-3">
                <div className="sitemap__linkscontainer__card">
                  <div className="sitemap__linkscontainer__card__heading">
                    School Information
                  </div>
                  <div className="sitemap__linkscontainer__card__link">
                    <a href="/cbse-corner/">CBSE Corner</a>
                  </div>
                  <div className="sitemap__linkscontainer__card__link">
                    <a href="/transfer-certificate/">Transfer Certificate</a>
                  </div>
                  <div className="sitemap__linkscontainer__card__link">
                    <a href="/rules-and-regulations/">Rules & Regulations</a>
                  </div>
                  <div className="sitemap__linkscontainer__card__link">
                    <a href="/transportation/">Transportation</a>
                  </div>
                  <div className="sitemap__linkscontainer__card__link">
                    <a href="/school-events/">School Events</a>
                  </div>
                  <div className="sitemap__linkscontainer__card__link">
                    <a href="/school-uniform/">School Uniform</a>
                  </div>
                  <div className="sitemap__linkscontainer__card__link">
                    <a href="/infrastructure/">Infrastructure</a>
                  </div>
                </div>
              </div>
              <div className="col col-6 col-md-4 col-lg-3">
                <div className="sitemap__linkscontainer__card">
                  <div className="sitemap__linkscontainer__card__heading">
                    Academics
                  </div>

                  <div className="sitemap__linkscontainer__card__link">
                    <a href="/syllabus/">Syllabus</a>
                  </div>
                  <div className="sitemap__linkscontainer__card__link">
                    <a href="/subjects/">Subjects</a>
                  </div>
                  <div className="sitemap__linkscontainer__card__link">
                    <a href="/exam-schedule/">Exam Schedule</a>
                  </div>
                </div>
              </div>
              <div className="col col-6 col-md-4 col-lg-3">
                <div className="sitemap__linkscontainer__card">
                  <div className="sitemap__linkscontainer__card__heading">
                    Co-Curriculars
                  </div>
                  <div className="sitemap__linkscontainer__card__link">
                    <a href="/laboratories/">Laboratories</a>
                  </div>
                  <div className="sitemap__linkscontainer__card__link">
                    <a href="/performaning-arts/">Performing Arts</a>
                  </div>
                  <div className="sitemap__linkscontainer__card__link">
                    <a href="/sports/">Sports</a>
                  </div>
                  <div className="sitemap__linkscontainer__card__link">
                    <a href="/club/">Clubs</a>
                  </div>
                  <div className="sitemap__linkscontainer__card__link">
                    <a href="/visual-arts/">Visual Arts</a>
                  </div>
                  <div className="sitemap__linkscontainer__card__link">
                    <a href="/vyaktitva/">Vyaktitva</a>
                  </div>
                  <div className="sitemap__linkscontainer__card__link">
                    <a href="/atl/">ATL</a>
                  </div>
                </div>
              </div>
              <div className="col col-6 col-md-4 col-lg-3">
                <div className="sitemap__linkscontainer__card">
                  <div className="sitemap__linkscontainer__card__heading">
                    Admissions
                  </div>
                  <div className="sitemap__linkscontainer__card__link">
                    <a href="/nursery-admissions/">Nursery</a>
                  </div>
                  <div className="sitemap__linkscontainer__card__link">
                    <a href="/admissions/">Other Classes</a>
                  </div>
                  <div className="sitemap__linkscontainer__card__link">
                    <a href="/online-registration-process/">
                      Application Process
                    </a>
                  </div>
                  <div className="sitemap__linkscontainer__card__link">
                    <a href="/registrations/">Registrations</a>
                  </div>
                </div>
              </div>
              <div className="col col-6 col-md-4 col-lg-3">
                <div className="sitemap__linkscontainer__card">
                  <div className="sitemap__linkscontainer__card__heading">
                    Achievements
                  </div>
                  <div className="sitemap__linkscontainer__card__link">
                    <a href="/cbse-results/">CBSE Results</a>
                  </div>
                  <div className="sitemap__linkscontainer__card__link">
                    <a href="/achievements/external-events">External Events</a>
                  </div>
                </div>
              </div>

              <div className="sitemap__images">
                <br />
                <img
                  className="sitemap__images__img"
                  alt=""
                  src="https://chinmayavvdelhi.ac.in/images/Blackele/cce1_1.png"
                ></img>

                <img
                  className="sitemap__images__img"
                  alt=""
                  src="https://chinmayavvdelhi.ac.in/assets/images/school-awards-logo.jpg"
                ></img>

                <img
                  className="sitemap__images__img"
                  alt=""
                  src="https://chinmayavvdelhi.ac.in/assets/images/Times-school-survey-icon.png"
                ></img>
                {/* <img

                  className="sitemap__images__img"
                  alt=""
                  src="https://chinmayavvdelhi.ac.in/images/Blackele/extramarks1_3.png"
                ></img>
                <img
                  className="sitemap__images__img"
                  alt=""
                  src="https://chinmayavvdelhi.ac.in/images/Blackele/rotary1_4.png"
                ></img>
                <img
                  className="sitemap__images__img"
                  alt=""
                  src={classEdge}
                ></img> */}
                <GoogleReviewWidget />
              </div>

              <br />
              {/* <address>
                <strong>Address:</strong>
                <br />
                Chinmaya Vidyalaya New Delhi
                <br />
                Near Basant Lok Complex, <br />
                Indian Air Lines & Air India Estate
                <br />
                Vasant Vihar, New Delhi, <br />
                Delhi 110057
                <br />
              </address> */}
            </div>
          </div>
          <div className="col col-12 col-lg-4 sitemap__linkscontainer__rightsection">
            <div className="sitemap__twittercontainer">
              <iframe
                title="Twitter"
                src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fchinmayavidyalayavv%2F&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=729337057890868"
                width="340"
                height="300"
                style={{
                  border: "none",
                  overflow: "hidden",
                  marginLeft: "auto",
                }}
                scrolling="no"
                frameborder="0"
                allowfullscreen="true"
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
              ></iframe>

              <iframe
                title="Google Maps"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3504.4524681888056!2d77.1582007!3d28.556172800000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1dba8b5b30f5%3A0x412ebeae9593273f!2sCHINMAYA%20VIDYALAYA!5e0!3m2!1sen!2sin!4v1699268603433!5m2!1sen!2sin"
                width="340"
                height="300"
                style={{
                  border: "none",
                  overflow: "hidden",
                  marginLeft: "auto",
                }}
                allowfullscreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
