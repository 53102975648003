import React from "react";
import NewsTicker from "../Newsticker";
import Navbar from "../Navbar";
import Footer from "../Footer";
import Sitemap from "../SitemapSection";
import "bootstrap/dist/css/bootstrap-grid.min.css";
import "./style.scss";
import StickySocial from "../StickySocial";
import NavbarMobile from "../NavbarMobile";

export default function Layout({ isHomepage = false, children }) {
  return (
    <main id="layout" className="layout">
      <StickySocial />
      {/* <NewsTicker /> */}

      <Navbar />
      <NavbarMobile />
      {children}
      <Sitemap />

      <Footer />
    </main>
  );
}
