import React, { useEffect, useState } from "react";
import "./style.scss";

export default function Footer() {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  useEffect(() => {
    setCurrentYear(new Date().getFullYear());
  }, []);
  return (
    <div className="footer">
      <div className="footer__left">
        © Chinmaya Vidyalaya {currentYear}. All Rights Reserved.
      </div>

      <address>
        Chinmaya Vidyalaya New Delhi
        <br />
        Near Basant Lok Complex, Indian Air Lines & Air India Estate
        <br />
        Vasant Vihar, New Delhi, Delhi 110057
        <br />
      </address>

      <div className="footer__right">
        Powered by{" "}
        <img
          className="footer__right__img"
          width="20"
          height="20"
          alt="Black Elephant Digital Logo"
          src="https://blackelephantdigital.com/assets/img/BE(LOGO)footer200x200.png"
        ></img>
      </div>
    </div>
  );
}
