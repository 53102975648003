import React, { useEffect } from "react";

function GoogleReviewWidget() {
  useEffect(() => {
    // Create a script element
    // Find the existing script element
    const existingScript = document.querySelector(
      'script[src="https://grwapi.net/widget.min.js"]'
    );

    // Remove the existing script if it exists
    if (existingScript) {
      existingScript.parentNode.removeChild(existingScript);
    }

    // Create a new script element
    const script = document.createElement("script");
    script.src = "https://grwapi.net/widget.min.js";
    script.async = true;

    // Append the new script to the document's head
    document.head.appendChild(script);
    // // Cleanup: Remove the script when the component unmounts
    // return () => {
    //   document.head.removeChild(script);
    // };
  }, []);

  return (
    <div>
      <div
        className="review-widget_net"
        data-uuid="9a8fe681-1916-42f2-95fe-7c7acccf70aa"
        data-template="10"
        data-lang="en"
        data-theme="light"
      ></div>
    </div>
  );
}

export default GoogleReviewWidget;
